<template>
    <div>
        <div class="row mt-2 mb-2">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Profile Settings</h4>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <h4 class="header-title mb-3">Password reset</h4>
                <p class="text-muted font-14">Password should contain at least 6 symbols</p>

                <div class="form-group">
                    <label for="simpleinput">Current password <i class="required-asterisk">*</i></label>
                    <input type="password" class="form-control" v-model="form.old_password" />
                </div>

                <div class="form-group">
                    <label for="simpleinput">New password <i class="required-asterisk">*</i></label>
                    <input type="password" class="form-control" v-model="form.new_password" />
                </div>

                <button v-if="!loading" class="btn btn-primary" @click="resetPassword()">
                    Reset
                </button>
                <button v-else class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Loading...
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { to, sleep } from '@/plugins/utils';

export default {
    name: 'ProfileSettings',
    data: () => {
        return {
            loading: false,
            resetF: {
                old_password: '',
                new_password: ''
            },
            form: {}
        }
    },
    async beforeMount() {
		this.resetForm();
	},
    methods: {
        resetForm() {
			this.form = Object.assign({}, this.resetF);
		},
        async resetPassword() {
			if (this.loading) return $.NotificationApp.send('Error', 'Please wait', 'top-right', '', 'error');

			if (!this.form.old_password) return $.NotificationApp.send('Error', 'Please, type your current password', 'top-right', '', 'error');
			if (!this.form.new_password) return $.NotificationApp.send('Error', 'Please, type your new password', 'top-right', '', 'error');

			this.loading = true;

			var [err, response] = await to(this.$http.post('/auth/change_password', this.form));
			if (err) {
                this.loading = false;
				if (err.response && err.response.data && err.response.data.error) {
					if (err.response.data.error == 'failed_validation') {
						return $.NotificationApp.send('Error', 'Please check your passwords', 'top-right', '', 'error');
                    }
				}

				return $.NotificationApp.send('Error', err.message, 'top-right', '', 'error');
			}
			if (!response.data.success) {
				this.loading = false;
                console.error('methods.submitForm', '$http.response', response.data);
                if(response.data.error == 'password_incorrect')
                    return $.NotificationApp.send('Error', 'Current password is incorrect', 'top-right', '', 'error');

				return $.NotificationApp.send('Error', response.data.error, 'top-right', '', 'error');
            }

            $.NotificationApp.send('Success', 'Password has been changed', 'top-right', '', 'success');
            
            await sleep(150)
            this.loading = false;
			this.resetForm()
		},
    }
}
</script>

<style>

</style>